import { Injectable } from '@angular/core';

import { ABP, Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { ValidationResult } from '@nexweb/shared/data-access/common';

import { AumUserActiveManagement } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserActiveService {
  private baseApiUrl = '/api/app/aum-user-active';

  constructor(private rest: RestService) {}

  getUserActives(params = {} as ABP.PageQueryParams): Observable<AumUserActiveManagement.Response> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: this.baseApiUrl,
      params,
    };

    return this.rest.request<null, AumUserActiveManagement.Response>(request);
  }

  addUserActive(body: AumUserActiveManagement.AumUserActive): Observable<AumUserActiveManagement.AumUserActive> {
    const request: Rest.Request<AumUserActiveManagement.AumUserActive> = {
      method: 'POST',
      url: this.baseApiUrl,
      body: Object.assign({}, body),
    };

    return this.rest.request<AumUserActiveManagement.AumUserActive, AumUserActiveManagement.AumUserActive>(request);
  }

  updateUserActive(body: AumUserActiveManagement.AumUserActive): Observable<AumUserActiveManagement.AumUserActive> {
    const url = `${this.baseApiUrl}/${body.id}`;
    delete body.id;

    const request: Rest.Request<AumUserActiveManagement.AumUserActive> = {
      method: 'PUT',
      url,
      body: Object.assign({}, body),
    };

    return this.rest.request<AumUserActiveManagement.AumUserActive, AumUserActiveManagement.AumUserActive>(request);
  }

  public validate$(body: AumUserActiveManagement.AumUserActive): Observable<ValidationResult[]> {
    const url = `${this.baseApiUrl}/${body.id}/validate`;

    const request: Rest.Request<AumUserActiveManagement.AumUserActive> = {
      method: 'POST',
      url,
      body,
    };

    return this.rest.request<AumUserActiveManagement.AumUserActive, ValidationResult[]>(request);
  }
}
