import { Injectable } from '@angular/core';

import { ABP, Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { CompanyManagement } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private companyApiUrl = '/api/Aum/ab-company';
  private companyRolesApiUrl = '/api/Aum/ab-company/available-company-roles';

  constructor(private rest: RestService) {}

  public getCompanies(params = {} as ABP.PageQueryParams): Observable<CompanyManagement.Response> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.companyApiUrl}/company-list`,
      params,
    };

    return this.rest.request<null, CompanyManagement.Response>(request);
  }

  public getCompanyAvailableRoles(companyId: number): Observable<CompanyManagement.CompanyRole[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.companyRolesApiUrl}/${companyId}`,
    };

    return this.rest.request<null, CompanyManagement.CompanyRole[]>(request);
  }

  public getCompanyAssignedRoles(companyId: number): Observable<CompanyManagement.CompanyRole[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.companyApiUrl}/assigned-company-roles/${companyId}`,
    };

    return this.rest.request<null, CompanyManagement.CompanyRole[]>(request);
  }

  public addCompany(body: CompanyManagement.Company): Observable<CompanyManagement.Company> {
    const request: Rest.Request<CompanyManagement.Company> = {
      method: 'POST',
      url: this.companyApiUrl,
      body,
    };

    return this.rest.request<CompanyManagement.Company, CompanyManagement.Company>(request);
  }

  public updateCompany(body: CompanyManagement.Company): Observable<CompanyManagement.Company> {
    const url = `${this.companyApiUrl}/${body.id}`;
    delete body.id;

    const request: Rest.Request<CompanyManagement.Company> = {
      method: 'PUT',
      url,
      body,
    };

    return this.rest.request<CompanyManagement.Company, CompanyManagement.Company>(request);
  }

  public updateCompanyRole(id: number, body: CompanyManagement.CompanyRole): Observable<CompanyManagement.CompanyRole> {
    const url = `${this.companyApiUrl}/update-company-role/${id}`;

    delete body.id;

    const request: Rest.Request<CompanyManagement.CompanyRole> = {
      method: 'POST',
      url,
      body,
    };

    return this.rest.request<CompanyManagement.CompanyRole, CompanyManagement.CompanyRole>(request);
  }

  public getCompany(companyId: number): Observable<CompanyManagement.Company> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.companyApiUrl}/${companyId}`,
      params: {
        id: companyId,
      },
    };

    return this.rest.request<null, CompanyManagement.Company>(request);
  }
}
