import { Injectable } from '@angular/core';

import { ABP, Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { ValidationResult } from '@nexweb/shared/data-access/common';

import { AumActiveRoleManagement } from '../models';
import { ActiveRole, AvailableUserRoles } from '../models/active-role.model';

@Injectable({
  providedIn: 'root',
})
export class ActiveRoleService {
  private activeRoleApiUrl = '/api/Aum/aum-role-active';
  private availableUserRolesUrl = '/api/Aum/aum-role-active/available-user-roles';

  constructor(private rest: RestService) {}

  getActiveRoles(params = {} as ABP.PageQueryParams): Observable<AumActiveRoleManagement.Response> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: this.activeRoleApiUrl,
      params,
    };

    return this.rest.request<null, AumActiveRoleManagement.Response>(request);
  }

  addActiveRole(body: ActiveRole): Observable<ActiveRole> {
    const request: Rest.Request<ActiveRole> = {
      method: 'POST',
      url: this.activeRoleApiUrl,
      body: Object.assign({}, body),
    };

    return this.rest.request<ActiveRole, ActiveRole>(request);
  }

  updateActiveRole(body: ActiveRole): Observable<ActiveRole> {
    const url = `${this.activeRoleApiUrl}/${body.id}`;
    delete body.id;

    const request: Rest.Request<ActiveRole> = {
      method: 'PUT',
      url,
      body: Object.assign({}, body),
    };

    return this.rest.request<ActiveRole, ActiveRole>(request);
  }

  availableUserRoles(id: number): Observable<AvailableUserRoles[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: this.availableUserRolesUrl,
      params: { personId: id },
    };

    return this.rest.request<null, AvailableUserRoles[]>(request);
  }

  public validate$(body: ActiveRole): Observable<ValidationResult[]> {
    const url = `${this.activeRoleApiUrl}/${body.id}/validate`;

    const request: Rest.Request<ActiveRole> = {
      method: 'POST',
      url,
      body,
    };

    return this.rest.request<ActiveRole, ValidationResult[]>(request);
  }
}
