import { Injectable } from '@angular/core';

import { Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { CompanyLookup, PersonTitle, RoleLookup, UserLookup } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AumLookupService {
  private baseApiUrl = '/api/Aum/ab-lookup';

  constructor(private rest: RestService) {}

  /**
   *
   * @returns
   */
  public getTsoCompanyLookups$(): Observable<CompanyLookup> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/tso-company-lookups`,
    };

    return this.rest.request<null, CompanyLookup>(request);
  }

  /**
   *
   * @returns
   */
  public getCompanyLookups$(includeAllOption?: boolean): Observable<CompanyLookup[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/company-lookups`,
      params: { includeAllOption },
    };

    return this.rest.request<null, CompanyLookup[]>(request);
  }

  /**
   *
   * @returns
   */
  public getTraderCompanyLookups$(): Observable<CompanyLookup> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/trader-company-lookups`,
    };

    return this.rest.request<null, CompanyLookup>(request);
  }

  /**
   *
   * @returns
   */
  public getBalanceResponsibleCompanyLookups$(): Observable<CompanyLookup> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/balance-responsible-company-lookups`,
    };

    return this.rest.request<null, CompanyLookup>(request);
  }

  /**
   *
   * @returns
   */
  public getTraderUserLookups$(): Observable<UserLookup> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/trader-user-lookups`,
    };

    return this.rest.request<null, UserLookup>(request);
  }

  /**
   *
   * @returns
   */
  public getMarketOperatorUserLookups$(): Observable<UserLookup[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/trader-user-lookups`,
    };

    return this.rest.request<null, UserLookup[]>(request);
  }

  /**
   *
   * @returns
   */
  public getMarketSurveillanceUserLookups$(): Observable<UserLookup[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/market-surveillance-user-lookups`,
    };

    return this.rest.request<null, UserLookup[]>(request);
  }

  /**
   * Get User Company Lookups
   * @returns Observable<CompanyLookup[]>
   */
  public getUserCompanyLookups$(): Observable<CompanyLookup[]> {
    const request: Rest.Request<null> = {
      method: `GET`,
      url: `${this.baseApiUrl}/user-company-lookups`,
    };

    return this.rest.request<null, CompanyLookup[]>(request);
  }

  /**
   * Get Role Lookups by Company Id
   * @param includeAllOption
   * @param companyId
   */
  public getRoleLookupsByCompany$(includeAllOption: boolean, companyId: number): Observable<RoleLookup[]> {
    const request: Rest.Request<null> = {
      method: `GET`,
      url: `${this.baseApiUrl}/role-lookups-by-company/${companyId}`,
      params: { includeAllOption },
    };

    return this.rest.request<null, RoleLookup[]>(request);
  }

  /**
   * Get Company User Lookups
   * @param companyId
   */
  public getCompanyUserLookups$(companyId: number): Observable<UserLookup[]> {
    const request: Rest.Request<null> = {
      method: `GET`,
      url: `${this.baseApiUrl}/company-user-lookups/${companyId}`,
      params: { companyId },
    };

    return this.rest.request<null, UserLookup[]>(request);
  }

  /**
   * Get Person Titles
   * @returns Observable<PersonTitle[]>
   */
  public getPersonTitles$(): Observable<PersonTitle[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/person-titles`,
    };

    return this.rest.request<null, PersonTitle[]>(request);
  }

  /**
   * Get All Users
   * @returns Observable<UserLookup[]>
   */
  public getAllUsers$(): Observable<UserLookup[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/users`,
    };

    return this.rest.request<null, UserLookup[]>(request);
  }
}
