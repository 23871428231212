import { Injectable } from '@angular/core';

import { ABP, Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { CountryManagement } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private countryApiUrl = '/api/Aum/ab-lookup/countries';

  constructor(private rest: RestService) {}

  getCountries(params = {} as ABP.PageQueryParams): Observable<CountryManagement.Response> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: this.countryApiUrl,
      params,
    };

    return this.rest.request<null, CountryManagement.Response>(request);
  }
}
