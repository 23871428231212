import { Injectable } from '@angular/core';

import { Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NexBaseFile } from '@nexweb/shared/data-access/common';

import {
  GetUserActivityLog,
  GetUserFailedLoginAttemptLog,
  SecurityAuditLog,
  SecurityFailedLoginAttemptLog,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class UserActivityLogService {
  private userActivityApiUrl = '/api/Aum/user-activity';

  constructor(private rest: RestService) {}

  public getSecurityLogs(params: GetUserActivityLog): Observable<SecurityAuditLog[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.userActivityApiUrl}/security-log`,
      params,
    };

    const userActivities = this.rest.request<null, SecurityAuditLog[]>(request);

    return this._setServiceAction(userActivities);
  }

  public getAuditLogs(params: GetUserActivityLog): Observable<SecurityAuditLog[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.userActivityApiUrl}/audit-log`,
      params,
    };

    const userActivities = this.rest.request<null, SecurityAuditLog[]>(request);

    return this._setServiceAction(userActivities);
  }

  public getUserActivityLogs(params: GetUserActivityLog): Observable<SecurityAuditLog[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.userActivityApiUrl}/user-activity-log`,
      params,
    };

    const userActivities = this.rest.request<null, SecurityAuditLog[]>(request);

    return this._setServiceAction(userActivities);
  }

  public getUserLatestActivityLogs(params: GetUserActivityLog): Observable<SecurityAuditLog[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.userActivityApiUrl}/user-latest-activity-log`,
      params,
    };

    const userActivities = this.rest.request<null, SecurityAuditLog[]>(request);

    return this._setServiceAction(userActivities);
  }

  public getFailedLoginAttemptLog$(params: GetUserFailedLoginAttemptLog): Observable<SecurityFailedLoginAttemptLog> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.userActivityApiUrl}/user-failed-login-attempt-log`,
      params,
    };

    return this.rest.request<null, SecurityFailedLoginAttemptLog>(request);
  }

  public getUserActivityLogsPdf$(params: GetUserActivityLog): Observable<NexBaseFile> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.userActivityApiUrl}/user-activity-logs-pdf`,
      params,
    };

    return this.rest.request<null, NexBaseFile>(request);
  }

  public getUserLatestActivityLogsPdf$(params: GetUserActivityLog): Observable<NexBaseFile> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.userActivityApiUrl}/user-latest-activity-logs-pdf`,
      params,
    };

    return this.rest.request<null, NexBaseFile>(request);
  }

  private _setServiceAction(userActivities: Observable<SecurityAuditLog[]>) {
    return userActivities.pipe(
      tap((result: SecurityAuditLog[]) => {
        for (const item of result) {
          if (item.action) {
            item.serviceAction = item.action.length === 1 ? item.action[0] : item.action.map((o) => o).join(' | ');
          }
        }
      }),
    );
  }
}
