import { Injectable } from '@angular/core';

import { Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ValidationResult } from '@nexweb/shared/data-access/common';

import { AbEmailManagement, AbPhoneManagement, PhoneNumber, UserManagement } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userApiUrl = '/api/Aum/ab-user';

  constructor(private rest: RestService) {}

  /**
   * Get Users
   * @returns Observable<UserManagement.Response>
   */
  public getUsers(): Observable<UserManagement.Response> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: this.userApiUrl,
    };

    const response = this.rest.request<null, UserManagement.Response>(request);

    return response.pipe(
      tap((result) => {
        result.items.forEach((items) => {
          if (items.emailAddresses.length !== 0) {
            items.emailAddress = items.emailAddresses[0].emailAddress;
          }
          if (items.phoneNumbers.length !== 0) {
            items.phoneNumber = items.phoneNumbers[0].phoneNumber;
          }

          items.fullName = items.firstName + ' ' + items.lastName;
        });
      }),
    );
  }

  /**
   * Add User
   * @param body
   * @returns Observable<UserManagement.User>
   */
  public addUser(body: UserManagement.User): Observable<UserManagement.User> {
    body = this.formatUserDetails(body);

    const request: Rest.Request<UserManagement.User> = {
      method: 'POST',
      url: this.userApiUrl,
      body: Object.assign({}, body),
    };

    return this.rest.request<UserManagement.User, UserManagement.User>(request);
  }

  /**
   * Update User
   * @param body
   * @returns Observable<UserManagement.User>
   */
  public updateUser(body: UserManagement.User): Observable<UserManagement.User> {
    const url = `${this.userApiUrl}/${body.id}`;

    if (body.emailAddresses.length > 0) {
      body.emailAddresses[0].emailAddress = body.emailAddress;
    } else {
      if (typeof body.emailAddress !== 'undefined' && body.emailAddress.length > 0) {
        body.emailAddresses.push({
          id: 0,
          personId: body.id,
          emailAddress: body.emailAddress,
          creationTime: new Date(),
          creatorId: body.abpUserId,
          emailConfirmed: true,
          emailDescription: 'Default Email',
          emailLabel: 0,
          isPrimary: true,
          lastModificationTime: new Date(),
          lastModifierId: body.abpUserId,
        });
      }
    }

    if (typeof body.phoneNumber === 'string') {
      if (body.phoneNumbers?.length > 0) {
        body.phoneNumbers[0].phoneNumber = body.phoneNumber;
      }
    } else {
      if (body.phoneNumbers?.length > 0) {
        body.phoneNumbers[0].phoneNumber = body.phoneNumber.number === '0' ? '0' : body.phoneNumber.internationalNumber;
        body.phoneNumbers[0].countryId = body.phoneNumber.countryId;
      } else {
        if (
          typeof body.phoneNumber?.internationalNumber !== 'undefined' &&
          body.phoneNumber?.internationalNumber.length > 0
        ) {
          body.phoneNumbers.push({
            id: 0,
            personId: body.id,
            phoneNumber: body.phoneNumber.internationalNumber,
            creationTime: new Date(),
            creatorId: body.abpUserId,
            phoneNumberConfirmed: true,
            phoneDescription: 'Default Email',
            phoneLabel: 0,
            isPrimary: true,
            lastModificationTime: new Date(),
            lastModifierId: body.abpUserId,
            countryId: body.phoneNumber.countryId,
          });
        }
      }
    }

    const request: Rest.Request<UserManagement.User> = {
      method: 'PUT',
      url,
      body: Object.assign({}, body),
    };

    return this.rest.request<UserManagement.User, UserManagement.User>(request);
  }

  /**
   * Delete User
   * @param id
   * @returns Observable<null>
   */
  public deleteUser(id: number): Observable<null> {
    const request: Rest.Request<null> = {
      method: 'DELETE',
      url: `${this.userApiUrl}/${id}`,
    };

    return this.rest.request<null, null>(request);
  }

  /**
   * Reset Password
   * @param body
   * @returns Observable<null>
   */
  public resetPassword(body: UserManagement.PasswordReset): Observable<null> {
    const request: Rest.Request<UserManagement.PasswordReset> = {
      method: 'PUT',
      url: `${this.userApiUrl}/password`,
      body,
    };

    return this.rest.request<UserManagement.PasswordReset, null>(request);
  }

  /**
   * Validate
   * @param body
   * @returns Observable<ValidationResult[]>
   */
  public validate$(body: UserManagement.User): Observable<ValidationResult[]> {
    const url = `${this.userApiUrl}/${body.id}/validate`;

    const request: Rest.Request<UserManagement.User> = {
      method: 'POST',
      url,
      body,
    };

    return this.rest.request<UserManagement.User, ValidationResult[]>(request);
  }

  public getUserReport$(): Observable<UserManagement.UserReport[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: this.userApiUrl + '/user-report',
    };

    return this.rest.request<null, UserManagement.UserReport[]>(request);
  }

  /**
   * Format User Details
   * @param body
   * @param isRegister
   * @returns UserManagement.User
   */
  private formatUserDetails(body: UserManagement.User, isRegister?: boolean): UserManagement.User {
    if (typeof body.emailAddress !== 'undefined' && body.emailAddress.length > 0) {
      body.emailAddresses = [
        {
          id: 0,
          personId: body.id,
          emailAddress: body.emailAddress,
          creationTime: isRegister ? body.emailAddresses[0].creationTime : new Date(),
          creatorId: body.abpUserId ?? '',
          emailConfirmed: true,
          emailDescription: 'Default Email',
          emailLabel: 1,
          isPrimary: true,
          lastModificationTime: isRegister ? body.emailAddresses[0].lastModificationTime : new Date(),
          lastModifierId: body.abpUserId ?? '',
        } as AbEmailManagement.AbEmail,
      ];
    }

    if (
      typeof (body.phoneNumber as PhoneNumber)?.internationalNumber !== 'undefined' &&
      (body.phoneNumber as PhoneNumber)?.internationalNumber.length > 0
    ) {
      body.phoneNumbers = [
        {
          id: 0,
          personId: body.id,
          phoneNumber: (body.phoneNumber as PhoneNumber).internationalNumber,
          creationTime: isRegister ? body.emailAddresses[0].creationTime : new Date(),
          creatorId: body.abpUserId ?? '',
          phoneNumberConfirmed: true,
          phoneDescription: 'Default Email',
          phoneLabel: 1,
          isPrimary: true,
          lastModificationTime: isRegister ? body.emailAddresses[0].lastModificationTime : new Date(),
          lastModifierId: body.abpUserId ?? '',
          countryId: (body.phoneNumber as PhoneNumber).countryId,
        } as AbPhoneManagement.AbPhone,
      ];
    }

    body.personTypeId = 1;

    return body;
  }
}
