export interface UserActivityOptions {
  userActivityLogType: UserActivityLogTypes;
}

export enum UserActivityLogTypes {
  auditLog,
  securityLog,
  userActivity,
  userLatestActivity,
}
